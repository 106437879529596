<template>
    <el-dialog
            :title="title"
            :close-on-click-modal="false"
            append-to-body
            class="dialogForm"
            :visible.sync="visible">
        <el-form size="small" :model="inputForm" :rules="dataRule" v-loading="loading" ref="inputForm"
                 @keyup.enter.native="doSubmit()"
                 label-width="140px" @submit.native.prevent>

            <el-form-item label="元数据名：" prop="fieldName">
                <el-input v-model="inputForm.fieldName" placeholder="请输入元数据名"></el-input>
            </el-form-item>

            <el-form-item label="元数据字段名：" prop="fieldEname">
                <el-input v-model="inputForm.fieldEname" placeholder="请输入元数据字段名"></el-input>
            </el-form-item>

            <el-form-item label="页面类型：" prop="pageTypes">
                <el-select multiple v-model="inputForm.pageTypes" placeholder="请选择页面类型" style="width: 100%">
                    <el-option
                            v-for="item in pageTypes"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>


            <el-form-item label="页面组件类型：" prop="detailsShowType">
                <el-select v-model="inputForm.detailsShowType" placeholder="请选择页面组件类型" style="width: 100%">
                    <el-option
                            v-for="item in detailsShowTypes"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="元数据类型：" prop="valType">
                <el-select v-model="inputForm.valType" placeholder="请选择元数据类型" style="width: 100%">
                    <el-option
                            v-for="item in valType"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="字典类型名称：" prop="dictType">
                <el-input v-model="inputForm.dictType" placeholder="请输入字典类型名称"></el-input>
            </el-form-item>


            <el-form-item label="元数据模板：" prop="masterTable">
                <el-select :disabled="true" v-model="inputForm.masterTable" placeholder="请选择元数据模板"
                           style="width: 100%">
                    <el-option
                            v-for="item in metadataTemplateList"
                            :key="item.id"
                            :label="item.resourceName"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="数据限制长度：" prop="limitLength">
                <el-input @input="inputForm.limitLength = inputForm.limitLength.replace(/[^\d]/g, '')"
                          @blur="setLimitLength"
                          maxlength="3" v-model.trim.number="inputForm.limitLength"
                          placeholder="请输入数据集合"></el-input>
            </el-form-item>
            <el-form-item label="数据集合：" prop="dataset">
                <el-input v-model.trim="inputForm.dataset" maxlength="50"
                          placeholder="请输入数据集合(限50字)"></el-input>
            </el-form-item>
            <el-form-item label="数据表达方式：" prop="expressionMethod">
                <el-input v-model.trim="inputForm.expressionMethod" maxlength="50"
                          placeholder="请输入数据表达方式(限50字)"></el-input>
            </el-form-item>


            <el-form-item label="元数据排序：" prop="fieldSort">
                <el-input-number v-model="inputForm.fieldSort" :min="1"></el-input-number>
            </el-form-item>

            <el-form-item label="是否显示：" prop="onShow">
                <el-switch v-model="inputForm.onShow" :active-value="0" :inactive-value="1"></el-switch>
            </el-form-item>

            <el-form-item label="是否发布：" prop="publishState">
                <el-switch v-model="inputForm.publishState" :active-value="0" :inactive-value="1"></el-switch>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer text_center">
            <el-button size="small" @click="visible = false">取消</el-button>
            <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,
            title: '',
            method: '',
            inputForm: {
                id: '',
                fieldName: '',
                fieldEname: '',
                fieldSort: '',
                publishState: 0,
                masterTable: '',
                pageType: '',
                pageTypes: [],
                onShow: 0,
                detailsShowType: '',
                dictType: '',
                valType: '',

                limitLength: 20,
                dataset: '',
                expressionMethod: '',
            },
            metadataTemplateList: [],
            fieldNameOptions: [],
            detailsShowTypes: [
                {value: 0, label: '文本输入框'},
                {value: 1, label: '下拉选择框'},
                {value: 2, label: '年度选择框'},
                {value: 3, label: '日期选择框'},
                {value: 4, label: 'switch开关'},
                {value: 5, label: '时间选择框'},
                {value: 6, label: '用户选择框'},
            ],
            pageTypes: [
                {value: 0, label: '列表'},
                {value: 1, label: '表单'},
                {value: 2, label: '高级查询'}
            ],
            valType: [
                {value: 0, label: '字符串'},
                {value: 1, label: '数字'},
                {value: 2, label: '时间'},
                {value: 3, label: '日期'},
            ],
            //数字类型
            numType: [
                {id: 0, label: '字符串'},
                {id: 1, label: '数字'},
                {id: 2, label: '时间'},
                {id: 3, label: '日期'},
            ],
            dataRule: {
                fieldName: [
                    {required: true, message: '元数据名不能为空', trigger: 'blur'}
                ],
                fieldEname: [
                    {required: true, message: '元数据字段名不能为空', trigger: 'change'}
                ],
                pageTypes: [
                    {required: true, message: '页面不能为空', trigger: 'change'}
                ],
                detailsShowType: [
                    {required: true, message: '页面组件类型不能为空', trigger: 'change'}
                ],
                valType: [
                    {required: true, message: '元数据类型不能为空', trigger: 'change'}
                ],
                metadataTemplate: [
                    {required: true, message: '元数据模板不能为空', trigger: 'change'}
                ]

            }
        }
    },
    mounted() {
        this.$axios(this.api.dyyg.originalSelectList, {}, 'get').then((res) => {
            if (res.status) {
                this.metadataTemplateList = res.data
            }
        })
    },

    methods: {
        init(method, row) {
            this.method = method
            console.log(row)
            if (method === 'add') {
                this.title = `新增元数据`
            } else if (method === 'edit') {
                this.title = '编辑元数据'
            } else if (method === 'copy') {
                this.title = `复制元数据`
            }

            this.visible = true
            this.$nextTick(() => {
                this.$refs['inputForm'].resetFields()
                if (method === 'add') {
                    this.inputForm.masterTable = row
                }
                if (method === 'edit' || method === 'copy') { // 修改
                    this.inputForm = this.recover(this.inputForm, row)
                    if (this.inputForm.pageTypes != null && this.inputForm.pageTypes != '') {
                        this.inputForm.pageTypes = this.inputForm.pageTypes.split(",").map(item => {
                            return parseInt(item);
                        })
                    }
                    this.inputForm.detailsShowType = this.inputForm.detailsShowType * 1
                    this.inputForm.valType = this.inputForm.valType * 1
                    this.inputForm.onShow = this.inputForm.onShow * 1
                    this.inputForm.publishState = this.inputForm.publishState * 1

                    console.log(this.inputForm.masterTable)
                    console.log(this.metadataTemplateList)
                }/* else if (method === 'copy') {
                        this.inputForm = this.recover(this.inputForm, row)
                    }*/
            })
        },
        // 表单提交
        doSubmit() {
            this.$refs['inputForm'].validate((valid) => {
                if (valid) {
                    this.loading = true
                    this.inputForm.pageTypes = this.inputForm.pageTypes.map(item => {
                        return item;
                    }).join(",")
                    if (this.title == `新增元数据` || this.title == `复制元数据`) {
                        this.$axios(this.api.dyyg.metaDataSave, this.inputForm, 'post').then(data => {
                            this.loading = false
                            if (data && data.status) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 1500
                                })
                                this.visible = false
                                this.$emit('refreshDataList')
                            }
                        })
                    } else {
                        this.$axios(this.api.dyyg.metaDataUpdateById, this.inputForm, 'post').then(data => {
                            this.loading = false
                            if (data && data.status) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 1500
                                })
                                this.visible = false
                                this.$emit('refreshDataList')
                            }
                        })
                    }
                }
            })
        },

        setLimitLength() {
            if (!this.inputForm.limitLength) {
                this.inputForm.limitLength = 1
            }
        },
    }
}
</script>
